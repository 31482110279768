<template>
    <div>
        <br />
        <div class="col-sm-12 text-right">
            <h1>Sesiones</h1>
        </div>
        <br />
        <DataGrid :config="dataGridConfig" :data="sesiones" @actions="dataGridActions" :select="seleccionar">
            <div class="col-sm-2">
                <button class="btn warning-btn">Recargar</button>
            </div>
            <div class="col-sm-2">
                <button class="btn danger-btn" @click="deshabilitar_sesion">
                    Deshabilitar
                </button>
            </div>
        </DataGrid>
        <Modal v-if="ver_confirmacion" :options="{ width: '30vw', type: 'error' }" @close="cancelar_confirmacion" class="bpb-modal">
            <h2 class="title">Deshabilitar sesiones</h2>
            <div class="body">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <p>
                            Vas a deshablitar {{ seleccion.length }} sesiones <br />¿Deseas
                            continuar?
                        </p>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div class="row">
                    <div class="col-sm-12 d-flex justify-content-center">
                        <div class="col-sm-3">
                            <button class="btn principal-btn" @click="deshabilitar_multiples">
                                Continuar
                            </button>
                        </div>
                        <div class="col-sm-3">
                            <button class="btn complementary-btn" @click="cancelar_confirmacion">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import DataGrid from "@/components/DataGrid";
import Modal from "@/components/Modal";

import api from "@/apps/account/api/account";

export default {
    components: {
        DataGrid,
        Modal,
    },
    data: function() {
        return {
            dataGridConfig: {
                name: "usuarios",
                cols: {
                    usuario_id: "Usuario",
                    hash: "Hash",
                    caducidad: "Caducidad",
                    created_at: 'Creación',
                    estatus: "Estatus",
                },
                filters: {
                    cols: {
                        id: "ID",
                        usuario_id: {
                        	text: "Usuario"
                        	,options: {}
                        },
                        hash: "Hash",
                        caducidad: "Caducidad",
                        estatus: {
                            text: "Estatus",
                            option: {
                                1: "Activo",
                                0: "Inactivo",
                            },
                        },
                        created_at: "Fecha de creación",
                    },
                },
                paginator: {
                    pagina_actual: 1,
                    total_registros: 1,
                    registros_por_pagina: 20,
                },
                mutators: {
                    usuario_id: function(val,row) {
                        return "("+row.usuario.id+") "+row.usuario.nombre;
                    },
                    caducidad: function(val, row, vue) {
                        return vue.$moment(val).valueOf() > vue.$moment().valueOf() ? val : "Caducado";
                    },
                    created_at: function(val,row, vue) {
                        return vue.$moment(val).format('YYYY-MM-DD HH:mm');
                    },
                    estatus: function(val) {
                        return val == 1 ? "Activo" : "Inactivo";
                    },
                },
            },
            options: {
                page: 1,
                order_col: "id",
                order_dir: "desc",
                limit: 20,
                filters: [],
            },
            seleccionar: false,
            seleccion: [],
            sesiones: [],
            ver_confirmacion: false,
        };
    },
    mounted: function() {
        this.obtener_sesiones();
        this.obtener_usuarios();
    },
    methods: {
        dataGridActions: function(tipo, valor) {
            if (tipo == "options") {
                this.options = valor;
                this.obtener_sesiones();
            } else {
                this.seleccion = valor;
                this.seleccionar = true;
            }
        },
        obtener_sesiones: async function() {
            let response = (await api.listar_sesiones(this.options)).data;

            this.sesiones = response.data;

            this.dataGridConfig.paginator.pagina_actual = response.current_page;
            this.dataGridConfig.paginator.total_registros = response.total;
            this.dataGridConfig.paginator.registros_por_pagina = parseInt(
                response.per_page
            );
        },
        obtener_usuarios: async function() {
			 let usuarios = (await api.listar_usuarios()).data;

			 usuarios.some(user => {
				this.dataGridConfig.filters.cols.usuario_id.options[user.id] = user.nombre;
			})
		},
        deshabilitar_sesion: async function() {
            if (this.seleccion.length > 0) {
                if (this.seleccion.length == 1) {
                    await api.deshabilitar_sesiones(this.seleccion[0].id, { estatus: 0 });
                    this.cancelar_confirmacion();
                    this.obtener_sesiones();
                } else this.ver_confirmacion = true;
            } else
                this.$helper.showMessage(
                    "Error",
                    "Tienes que seleccionar la sesion a deshabilitar",
                    "error",
                    "alert"
                );
        },
        cancelar_confirmacion: function() {
            this.seleccionar = false;
            this.ver_confirmacion = false;
        },
        deshabilitar_multiples: async function() {
            for (let i = 0; i < this.seleccion.length; i++) {
                await api.deshabilitar_sesiones(this.seleccion[i].id, { estatus: 0 });
            }

            this.cancelar_confirmacion();
            this.obtener_sesiones();
        },
    },
};
</script>